program CnCApp;

{$R *.dres}

uses
  WEBLib.Forms,
  Main in 'Main.pas'{*.html},
  Data in 'Data.pas',
  Login in 'Login.pas'{*.html},
  SigCap in 'SigCap.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  //Application.CreateForm(TFmain, Fmain);
  Application.CreateForm(TDm, Dm);
  Application.CreateForm(TFLogin, FLogin);
  //Application.CreateForm(TFSigCap, FSigCap);
  Application.Run;
end.
