unit SigCap;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.TMSFNCTypes, WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, WEBLib.StdCtrls, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCSignatureCapture, WEBLib.ExtCtrls, DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset;

type
  TFSigCap = class(TForm)
    SigPnl: TPanel;
    WIC: TImageControl;
    SigCap: TTMSFNCSignatureCapture;
    WebButton2: TButton;
    DSSignatures: TXDataWebDataSet;
    DSSignaturesAutoInc: TIntegerField;
    DSSignaturesOrdernum: TIntegerField;
    DSSignaturesSignature: TStringField;
    WDSSignatures: TDataSource;
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
  protected
    procedure LoadDFMValues; override;
  public
    { Public declarations }
  end;

var
  FSigCap: TFSigCap;

implementation

Uses Data,Main;


procedure TFSigCap.WebButton1Click(Sender: TObject);
begin
  FSigCap.Close;
end;

procedure TFSigCap.WebButton2Click(Sender: TObject);
begin
  DSSignatures.Append;
  DSSignaturesOrdernum.AsInteger := FMain.DSOrdersOrderNum.AsInteger;
  DSSignaturesSignature.Value := SigCap.GetBase64Img;
  DSSignatures.Post;
  DSSignatures.ApplyUpdates;
  //ShowMessage(SigCap.GetBase64Img);
  FSigCap.Close;
  // FOrderDetail.Free;
  Application.CreateForm(TFMain, FMain);
  ShowMessage('Signature Saved');
end;

procedure TFSigCap.WebFormShow(Sender: TObject);
begin
  SigPnl.Left := Round((FSigCap.Width / 2) - (SigPnl.Width / 2));
  SigPnl.Top := Round((FSigCap.Height / 2) - (SigPnl.Height / 2));
  if SigPnl.Top < 0 then SigPnl.Top := 16;
  DSSignatures.QueryString := '$filter=(OrderNum eq 0)';
  DSSignatures.Load;
end;

procedure TFSigCap.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SigPnl := TPanel.Create(Self);
  WIC := TImageControl.Create(Self);
  SigCap := TTMSFNCSignatureCapture.Create(Self);
  WebButton2 := TButton.Create(Self);
  DSSignatures := TXDataWebDataSet.Create(Self);
  DSSignaturesAutoInc := TIntegerField.Create(Self);
  DSSignaturesOrdernum := TIntegerField.Create(Self);
  DSSignaturesSignature := TStringField.Create(Self);
  WDSSignatures := TDataSource.Create(Self);

  SigPnl.BeforeLoadDFMValues;
  WIC.BeforeLoadDFMValues;
  SigCap.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  DSSignatures.BeforeLoadDFMValues;
  DSSignaturesAutoInc.BeforeLoadDFMValues;
  DSSignaturesOrdernum.BeforeLoadDFMValues;
  DSSignaturesSignature.BeforeLoadDFMValues;
  WDSSignatures.BeforeLoadDFMValues;
  try
    Name := 'FSigCap';
    Width := 580;
    Height := 862;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    SigPnl.SetParentComponent(Self);
    SigPnl.Name := 'SigPnl';
    SigPnl.Left := 8;
    SigPnl.Top := 8;
    SigPnl.Width := 564;
    SigPnl.Height := 846;
    SigPnl.ElementClassName := 'container-fluid';
    SigPnl.BorderColor := clSilver;
    SigPnl.BorderStyle := bsSingle;
    SigPnl.ChildOrder := 1;
    WIC.SetParentComponent(SigPnl);
    WIC.Name := 'WIC';
    WIC.Left := 290;
    WIC.Top := 565;
    WIC.Width := 100;
    WIC.Height := 41;
    WIC.ChildOrder := 3;
    WIC.Visible := False;
    SigCap.SetParentComponent(SigPnl);
    SigCap.Name := 'SigCap';
    SigCap.Left := 8;
    SigCap.Top := 5;
    SigCap.Width := 547;
    SigCap.Height := 791;
    SigCap.ParentDoubleBuffered := False;
    SigCap.DoubleBuffered := True;
    SigCap.Font.Charset := DEFAULT_CHARSET;
    SigCap.Font.Color := clWindowText;
    SigCap.Font.Height := -11;
    SigCap.Font.Name := 'Tahoma';
    SigCap.Font.Style := [];
    SigCap.TabOrder := 0;
    SigCap.Pen.Color := clBlack;
    SigCap.TextPosition.Line.Color := clGray;
    SigCap.ClearSig.Image.LoadFromFile('SigCap.SigPnl.SigCap.ClearSig.Image.png');
    WebButton2.SetParentComponent(SigPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 376;
    WebButton2.Top := 802;
    WebButton2.Width := 158;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Save';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn btn-success';
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -19;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.ParentFont := False;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    DSSignatures.SetParentComponent(Self);
    DSSignatures.Name := 'DSSignatures';
    DSSignatures.EntitySetName := 'CC_Signatures';
    DSSignatures.Connection := Dm.ApiConnection;
    DSSignatures.Left := 384;
    DSSignatures.Top := 552;
    DSSignaturesAutoInc.SetParentComponent(DSSignatures);
    DSSignaturesAutoInc.Name := 'DSSignaturesAutoInc';
    DSSignaturesAutoInc.FieldName := 'AutoInc';
    DSSignaturesAutoInc.Required := True;
    DSSignaturesOrdernum.SetParentComponent(DSSignatures);
    DSSignaturesOrdernum.Name := 'DSSignaturesOrdernum';
    DSSignaturesOrdernum.FieldName := 'Ordernum';
    DSSignaturesSignature.SetParentComponent(DSSignatures);
    DSSignaturesSignature.Name := 'DSSignaturesSignature';
    DSSignaturesSignature.FieldName := 'Signature';
    DSSignaturesSignature.Size := 255;
    WDSSignatures.SetParentComponent(Self);
    WDSSignatures.Name := 'WDSSignatures';
    WDSSignatures.DataSet := DSSignatures;
    WDSSignatures.Left := 448;
    WDSSignatures.Top := 536;
  finally
    SigPnl.AfterLoadDFMValues;
    WIC.AfterLoadDFMValues;
    SigCap.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    DSSignatures.AfterLoadDFMValues;
    DSSignaturesAutoInc.AfterLoadDFMValues;
    DSSignaturesOrdernum.AfterLoadDFMValues;
    DSSignaturesSignature.AfterLoadDFMValues;
    WDSSignatures.AfterLoadDFMValues;
  end;
end;

end.