unit Data;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Modules, XData.Web.Connection;

type
  TDm = class(TDataModule)
    ApiConnection: TXDataWebConnection;
  private
    { Private declarations }
  protected
    procedure LoadDFMValues; override;
  public
    { Public declarations }
  end;

var
  Dm: TDm;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}


procedure TDm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ApiConnection := TXDataWebConnection.Create(Self);

  ApiConnection.BeforeLoadDFMValues;
  try
    Name := 'Dm';
    Height := 236;
    Width := 285;
    ApiConnection.SetParentComponent(Self);
    ApiConnection.Name := 'ApiConnection';
    ApiConnection.URL := 'https://www.mymvf.com:2002/tms/xdata';
    ApiConnection.Connected := True;
    ApiConnection.Left := 39;
    ApiConnection.Top := 16;
  finally
    ApiConnection.AfterLoadDFMValues;
  end;
end;

end.
