unit Main;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.DB, WEBLib.ExtCtrls, WEBLib.Grids,
  WEBLib.DBCtrls, WEBLib.StdCtrls;

type
  TFmain = class(TForm)
    OrdersPnl: TPanel;
    WebButton1: TButton;
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebEdit1: TEdit;
    WebLabel1: TLabel;
    WebButton2: TButton;
    WebDBGrid1: TDBGrid;
    DSOrders: TXDataWebDataSet;
    DSOrdersAutoInc: TIntegerField;
    DSOrdersOrderNum: TIntegerField;
    DSOrdersTrip: TIntegerField;
    DSOrdersInvoiceTotal: TFloatField;
    DSOrdersInvoiceNum: TIntegerField;
    DSOrdersDate: TDateTimeField;
    DSOrdersShipCust: TIntegerField;
    DSOrdersShipName: TStringField;
    DSOrdersShipCity: TStringField;
    DSOrdersShipState: TStringField;
    DSOrdersPcsTotal: TIntegerField;
    DSOrdersWindowArriveTime: TDateTimeField;
    DSLines: TXDataWebDataSet;
    DSLinesAutoInc: TIntegerField;
    DSLinesOrderNum: TIntegerField;
    DSLinesProd: TIntegerField;
    DSLinesQtyShip: TIntegerField;
    DSLinesCatchWeight: TFloatField;
    DSLinesProdUnit: TStringField;
    DSLinesProdSize: TStringField;
    DSLinesProdDescription: TStringField;
    DSLinesProdBrand: TStringField;
    WDSLines: TDataSource;
    WDSOrders: TDataSource;
    WebDBLabel1: TDBLabel;
    WebDBLabel2: TDBLabel;
    WebDBLabel3: TDBLabel;
    WebDBLabel4: TDBLabel;
    WebDBLabel5: TDBLabel;
    WebLabel2: TLabel;
    procedure WebFormShow(Sender: TObject);
    procedure WebEdit1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure DSOrdersAfterOpen(DataSet: TDataSet);
    procedure DSLinesAfterOpen(DataSet: TDataSet);
    procedure WebButton1Click(Sender: TObject);
    procedure WebEdit1KeyPress(Sender: TObject; var Key: Char);
  private
    { Private declarations }
  protected
    procedure LoadDFMValues; override;
  public
    { Public declarations }
  end;

var
  Fmain: TFmain;

implementation

Uses Data, SigCap;


procedure TFmain.DSLinesAfterOpen(DataSet: TDataSet);
begin
  WebPanel2.Visible := True;
end;

procedure TFmain.DSOrdersAfterOpen(DataSet: TDataSet);
begin
  if DSOrders.RecordCount = 0 then
  begin
    ShowMessage('Invalid Invoice Number');
    Exit;
  end;
  DSLines.Close;
  DSLines.QueryString := '$filter=(OrderNum eq '+DSOrdersOrderNum.AsString+' and Prod ne 0)&$orderby=Prod';
  DSLines.Load;
end;

procedure TFmain.WebButton1Click(Sender: TObject);
begin
  Application.CreateForm(TFSigCap, FSigCap);
end;

procedure TFmain.WebButton2Click(Sender: TObject);
begin
  DSOrders.Close;
  DSOrders.QueryString := '$filter=(OrderNum eq '+WebEdit1.Text+')&$orderby=ShipName';
  DSOrders.Load;
end;

procedure TFmain.WebEdit1Click(Sender: TObject);
begin
  WebEdit1.Clear;
end;

procedure TFmain.WebEdit1KeyPress(Sender: TObject; var Key: Char);
begin
  if key = #13 then
  begin
    WebButton2.Click;
  end;
end;

procedure TFmain.WebFormShow(Sender: TObject);
begin
  OrdersPnl.Left := Round((FMain.Width / 2) - (OrdersPnl.Width / 2));
  OrdersPnl.Top := Round((FMain.Height / 2) - (OrdersPnl.Height / 2));
  if OrdersPnl.Top < 0 then OrdersPnl.Top := 2;
end;

procedure TFmain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  OrdersPnl := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebDBLabel4 := TDBLabel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  DSOrders := TXDataWebDataSet.Create(Self);
  DSOrdersAutoInc := TIntegerField.Create(Self);
  DSOrdersOrderNum := TIntegerField.Create(Self);
  DSOrdersTrip := TIntegerField.Create(Self);
  DSOrdersInvoiceTotal := TFloatField.Create(Self);
  DSOrdersInvoiceNum := TIntegerField.Create(Self);
  DSOrdersDate := TDateTimeField.Create(Self);
  DSOrdersShipCust := TIntegerField.Create(Self);
  DSOrdersShipName := TStringField.Create(Self);
  DSOrdersShipCity := TStringField.Create(Self);
  DSOrdersShipState := TStringField.Create(Self);
  DSOrdersPcsTotal := TIntegerField.Create(Self);
  DSOrdersWindowArriveTime := TDateTimeField.Create(Self);
  DSLines := TXDataWebDataSet.Create(Self);
  DSLinesAutoInc := TIntegerField.Create(Self);
  DSLinesOrderNum := TIntegerField.Create(Self);
  DSLinesProd := TIntegerField.Create(Self);
  DSLinesQtyShip := TIntegerField.Create(Self);
  DSLinesCatchWeight := TFloatField.Create(Self);
  DSLinesProdUnit := TStringField.Create(Self);
  DSLinesProdSize := TStringField.Create(Self);
  DSLinesProdDescription := TStringField.Create(Self);
  DSLinesProdBrand := TStringField.Create(Self);
  WDSLines := TDataSource.Create(Self);
  WDSOrders := TDataSource.Create(Self);

  OrdersPnl.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebDBLabel4.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  DSOrders.BeforeLoadDFMValues;
  DSOrdersAutoInc.BeforeLoadDFMValues;
  DSOrdersOrderNum.BeforeLoadDFMValues;
  DSOrdersTrip.BeforeLoadDFMValues;
  DSOrdersInvoiceTotal.BeforeLoadDFMValues;
  DSOrdersInvoiceNum.BeforeLoadDFMValues;
  DSOrdersDate.BeforeLoadDFMValues;
  DSOrdersShipCust.BeforeLoadDFMValues;
  DSOrdersShipName.BeforeLoadDFMValues;
  DSOrdersShipCity.BeforeLoadDFMValues;
  DSOrdersShipState.BeforeLoadDFMValues;
  DSOrdersPcsTotal.BeforeLoadDFMValues;
  DSOrdersWindowArriveTime.BeforeLoadDFMValues;
  DSLines.BeforeLoadDFMValues;
  DSLinesAutoInc.BeforeLoadDFMValues;
  DSLinesOrderNum.BeforeLoadDFMValues;
  DSLinesProd.BeforeLoadDFMValues;
  DSLinesQtyShip.BeforeLoadDFMValues;
  DSLinesCatchWeight.BeforeLoadDFMValues;
  DSLinesProdUnit.BeforeLoadDFMValues;
  DSLinesProdSize.BeforeLoadDFMValues;
  DSLinesProdDescription.BeforeLoadDFMValues;
  DSLinesProdBrand.BeforeLoadDFMValues;
  WDSLines.BeforeLoadDFMValues;
  WDSOrders.BeforeLoadDFMValues;
  try
    Name := 'Fmain';
    Width := 550;
    Height := 860;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    OrdersPnl.SetParentComponent(Self);
    OrdersPnl.Name := 'OrdersPnl';
    OrdersPnl.Left := 8;
    OrdersPnl.Top := 8;
    OrdersPnl.Width := 529;
    OrdersPnl.Height := 844;
    OrdersPnl.ElementClassName := 'card';
    OrdersPnl.BorderColor := clSilver;
    OrdersPnl.BorderStyle := bsSingle;
    OrdersPnl.ElementBodyClassName := 'card-body';
    WebButton1.SetParentComponent(OrdersPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 344;
    WebButton1.Top := 784;
    WebButton1.Width := 161;
    WebButton1.Height := 49;
    WebButton1.Caption := 'Get Signature';
    WebButton1.ChildOrder := 1;
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -16;
    WebButton1.Font.Name := 'Tahoma';
    WebButton1.Font.Style := [];
    WebButton1.ParentFont := False;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebPanel1.SetParentComponent(OrdersPnl);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 3;
    WebPanel1.Top := 3;
    WebPanel1.Width := 523;
    WebPanel1.Height := 70;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 13;
    WebLabel1.Width := 145;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'Enter Order Number';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.ParentFont := False;
    WebEdit1.SetParentComponent(WebPanel1);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 24;
    WebEdit1.Top := 38;
    WebEdit1.Width := 145;
    WebEdit1.Height := 25;
    WebEdit1.EditType := weNumeric;
    WebEdit1.Font.Charset := DEFAULT_CHARSET;
    WebEdit1.Font.Color := clWindowText;
    WebEdit1.Font.Height := -16;
    WebEdit1.Font.Name := 'Tahoma';
    WebEdit1.Font.Style := [];
    WebEdit1.ParentFont := False;
    SetEvent(WebEdit1, Self, 'OnClick', 'WebEdit1Click');
    SetEvent(WebEdit1, Self, 'OnKeyPress', 'WebEdit1KeyPress');
    WebButton2.SetParentComponent(WebPanel1);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 320;
    WebButton2.Top := 13;
    WebButton2.Width := 161;
    WebButton2.Height := 49;
    WebButton2.Caption := 'Load Invoice';
    WebButton2.ChildOrder := 2;
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -16;
    WebButton2.Font.Name := 'Tahoma';
    WebButton2.Font.Style := [];
    WebButton2.ParentFont := False;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebPanel2.SetParentComponent(OrdersPnl);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 3;
    WebPanel2.Top := 79;
    WebPanel2.Width := 523;
    WebPanel2.Height := 699;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 1;
    WebPanel2.Font.Charset := DEFAULT_CHARSET;
    WebPanel2.Font.Color := clWindowText;
    WebPanel2.Font.Height := -13;
    WebPanel2.Font.Name := 'Tahoma';
    WebPanel2.Font.Style := [];
    WebPanel2.ParentFont := False;
    WebPanel2.Visible := False;
    WebDBLabel1.SetParentComponent(WebPanel2);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 11;
    WebDBLabel1.Top := 11;
    WebDBLabel1.Width := 43;
    WebDBLabel1.Height := 19;
    WebDBLabel1.Caption := 'Cust#';
    WebDBLabel1.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel1.Font.Color := clWindowText;
    WebDBLabel1.Font.Height := -16;
    WebDBLabel1.Font.Name := 'Tahoma';
    WebDBLabel1.Font.Style := [];
    WebDBLabel1.ParentFont := False;
    WebDBLabel1.DataField := 'ShipCust';
    WebDBLabel1.DataSource := WDSOrders;
    WebDBLabel2.SetParentComponent(WebPanel2);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 75;
    WebDBLabel2.Top := 11;
    WebDBLabel2.Width := 41;
    WebDBLabel2.Height := 19;
    WebDBLabel2.Caption := 'Name';
    WebDBLabel2.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel2.Font.Color := clWindowText;
    WebDBLabel2.Font.Height := -16;
    WebDBLabel2.Font.Name := 'Tahoma';
    WebDBLabel2.Font.Style := [];
    WebDBLabel2.ParentFont := False;
    WebDBLabel2.DataField := 'ShipName';
    WebDBLabel2.DataSource := WDSOrders;
    WebDBLabel3.SetParentComponent(WebPanel2);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 75;
    WebDBLabel3.Top := 36;
    WebDBLabel3.Width := 27;
    WebDBLabel3.Height := 19;
    WebDBLabel3.Caption := 'City';
    WebDBLabel3.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel3.Font.Color := clWindowText;
    WebDBLabel3.Font.Height := -16;
    WebDBLabel3.Font.Name := 'Tahoma';
    WebDBLabel3.Font.Style := [];
    WebDBLabel3.ParentFont := False;
    WebDBLabel3.DataField := 'ShipCity';
    WebDBLabel3.DataSource := WDSOrders;
    WebDBLabel4.SetParentComponent(WebPanel2);
    WebDBLabel4.Name := 'WebDBLabel4';
    WebDBLabel4.Left := 291;
    WebDBLabel4.Top := 36;
    WebDBLabel4.Width := 19;
    WebDBLabel4.Height := 19;
    WebDBLabel4.Caption := 'ST';
    WebDBLabel4.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel4.Font.Color := clWindowText;
    WebDBLabel4.Font.Height := -16;
    WebDBLabel4.Font.Name := 'Tahoma';
    WebDBLabel4.Font.Style := [];
    WebDBLabel4.ParentFont := False;
    WebDBLabel4.DataField := 'ShipState';
    WebDBLabel4.DataSource := WDSOrders;
    WebDBLabel5.SetParentComponent(WebPanel2);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 379;
    WebDBLabel5.Top := 36;
    WebDBLabel5.Width := 113;
    WebDBLabel5.Height := 19;
    WebDBLabel5.Alignment := taRightJustify;
    WebDBLabel5.Caption := 'Invoice Amount';
    WebDBLabel5.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel5.Font.Color := clWindowText;
    WebDBLabel5.Font.Height := -16;
    WebDBLabel5.Font.Name := 'Tahoma';
    WebDBLabel5.Font.Style := [];
    WebDBLabel5.ParentFont := False;
    WebDBLabel5.DataField := 'InvoiceTotal';
    WebDBLabel5.DataSource := WDSOrders;
    WebLabel2.SetParentComponent(WebPanel2);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 379;
    WebLabel2.Top := 11;
    WebLabel2.Width := 113;
    WebLabel2.Height := 19;
    WebLabel2.Caption := 'Invoice Amount';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebDBGrid1.SetParentComponent(WebPanel2);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 3;
    WebDBGrid1.Top := 61;
    WebDBGrid1.Width := 517;
    WebDBGrid1.Height := 635;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Prod';
      Title := 'Prod';
      Width := 58;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'ProdUnit';
      Title := 'Unit';
      Width := 46;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'ProdSize';
      Title := 'Size';
      Width := 54;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'ProdBrand';
      Title := 'Brand';
      Width := 69;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'ProdDescription';
      Title := 'Description';
      Width := 198;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'CatchWeight';
      Title := 'C/W';
      Width := 44;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'QtyShip';
      Title := 'Qty';
      Width := 30;
    end;
    WebDBGrid1.DataSource := WDSLines;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Font.Charset := DEFAULT_CHARSET;
    WebDBGrid1.Font.Color := clWindowText;
    WebDBGrid1.Font.Height := -16;
    WebDBGrid1.Font.Name := 'Tahoma';
    WebDBGrid1.Font.Style := [];
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid1.ParentFont := False;
    WebDBGrid1.TabOrder := 0;
    WebDBGrid1.ColWidths[0] := 58;
    WebDBGrid1.ColWidths[1] := 46;
    WebDBGrid1.ColWidths[2] := 54;
    WebDBGrid1.ColWidths[3] := 69;
    WebDBGrid1.ColWidths[4] := 198;
    WebDBGrid1.ColWidths[5] := 44;
    WebDBGrid1.ColWidths[6] := 30;
    DSOrders.SetParentComponent(Self);
    DSOrders.Name := 'DSOrders';
    DSOrders.AfterOpen := DSOrdersAfterOpen;
    DSOrders.EntitySetName := 'CC_Orders';
    DSOrders.Connection := Dm.ApiConnection;
    DSOrders.Left := 456;
    DSOrders.Top := 40;
    DSOrdersAutoInc.SetParentComponent(DSOrders);
    DSOrdersAutoInc.Name := 'DSOrdersAutoInc';
    DSOrdersAutoInc.FieldName := 'AutoInc';
    DSOrdersAutoInc.Required := True;
    DSOrdersOrderNum.SetParentComponent(DSOrders);
    DSOrdersOrderNum.Name := 'DSOrdersOrderNum';
    DSOrdersOrderNum.FieldName := 'OrderNum';
    DSOrdersTrip.SetParentComponent(DSOrders);
    DSOrdersTrip.Name := 'DSOrdersTrip';
    DSOrdersTrip.FieldName := 'Trip';
    DSOrdersInvoiceTotal.SetParentComponent(DSOrders);
    DSOrdersInvoiceTotal.Name := 'DSOrdersInvoiceTotal';
    DSOrdersInvoiceTotal.FieldName := 'InvoiceTotal';
    DSOrdersInvoiceTotal.DisplayFormat := '0.00';
    DSOrdersInvoiceNum.SetParentComponent(DSOrders);
    DSOrdersInvoiceNum.Name := 'DSOrdersInvoiceNum';
    DSOrdersInvoiceNum.FieldName := 'InvoiceNum';
    DSOrdersDate.SetParentComponent(DSOrders);
    DSOrdersDate.Name := 'DSOrdersDate';
    DSOrdersDate.FieldName := 'Date';
    DSOrdersShipCust.SetParentComponent(DSOrders);
    DSOrdersShipCust.Name := 'DSOrdersShipCust';
    DSOrdersShipCust.FieldName := 'ShipCust';
    DSOrdersShipName.SetParentComponent(DSOrders);
    DSOrdersShipName.Name := 'DSOrdersShipName';
    DSOrdersShipName.FieldName := 'ShipName';
    DSOrdersShipName.Size := 25;
    DSOrdersShipCity.SetParentComponent(DSOrders);
    DSOrdersShipCity.Name := 'DSOrdersShipCity';
    DSOrdersShipCity.FieldName := 'ShipCity';
    DSOrdersShipCity.Size := 25;
    DSOrdersShipState.SetParentComponent(DSOrders);
    DSOrdersShipState.Name := 'DSOrdersShipState';
    DSOrdersShipState.FieldName := 'ShipState';
    DSOrdersShipState.Size := 2;
    DSOrdersPcsTotal.SetParentComponent(DSOrders);
    DSOrdersPcsTotal.Name := 'DSOrdersPcsTotal';
    DSOrdersPcsTotal.FieldName := 'PcsTotal';
    DSOrdersWindowArriveTime.SetParentComponent(DSOrders);
    DSOrdersWindowArriveTime.Name := 'DSOrdersWindowArriveTime';
    DSOrdersWindowArriveTime.FieldName := 'WindowArriveTime';
    DSLines.SetParentComponent(Self);
    DSLines.Name := 'DSLines';
    DSLines.AfterOpen := DSLinesAfterOpen;
    DSLines.EntitySetName := 'CC_Lines';
    DSLines.Connection := Dm.ApiConnection;
    DSLines.Left := 432;
    DSLines.Top := 728;
    DSLinesAutoInc.SetParentComponent(DSLines);
    DSLinesAutoInc.Name := 'DSLinesAutoInc';
    DSLinesAutoInc.FieldName := 'AutoInc';
    DSLinesAutoInc.Required := True;
    DSLinesOrderNum.SetParentComponent(DSLines);
    DSLinesOrderNum.Name := 'DSLinesOrderNum';
    DSLinesOrderNum.FieldName := 'OrderNum';
    DSLinesOrderNum.Required := True;
    DSLinesProd.SetParentComponent(DSLines);
    DSLinesProd.Name := 'DSLinesProd';
    DSLinesProd.FieldName := 'Prod';
    DSLinesQtyShip.SetParentComponent(DSLines);
    DSLinesQtyShip.Name := 'DSLinesQtyShip';
    DSLinesQtyShip.FieldName := 'QtyShip';
    DSLinesCatchWeight.SetParentComponent(DSLines);
    DSLinesCatchWeight.Name := 'DSLinesCatchWeight';
    DSLinesCatchWeight.FieldName := 'CatchWeight';
    DSLinesProdUnit.SetParentComponent(DSLines);
    DSLinesProdUnit.Name := 'DSLinesProdUnit';
    DSLinesProdUnit.FieldName := 'ProdUnit';
    DSLinesProdUnit.Size := 7;
    DSLinesProdSize.SetParentComponent(DSLines);
    DSLinesProdSize.Name := 'DSLinesProdSize';
    DSLinesProdSize.FieldName := 'ProdSize';
    DSLinesProdSize.Size := 10;
    DSLinesProdDescription.SetParentComponent(DSLines);
    DSLinesProdDescription.Name := 'DSLinesProdDescription';
    DSLinesProdDescription.FieldName := 'ProdDescription';
    DSLinesProdDescription.Size := 30;
    DSLinesProdBrand.SetParentComponent(DSLines);
    DSLinesProdBrand.Name := 'DSLinesProdBrand';
    DSLinesProdBrand.FieldName := 'ProdBrand';
    DSLinesProdBrand.Size := 10;
    WDSLines.SetParentComponent(Self);
    WDSLines.Name := 'WDSLines';
    WDSLines.DataSet := DSLines;
    WDSLines.Left := 475;
    WDSLines.Top := 735;
    WDSOrders.SetParentComponent(Self);
    WDSOrders.Name := 'WDSOrders';
    WDSOrders.DataSet := DSOrders;
    WDSOrders.Left := 480;
    WDSOrders.Top := 56;
  finally
    OrdersPnl.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebDBLabel4.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    DSOrders.AfterLoadDFMValues;
    DSOrdersAutoInc.AfterLoadDFMValues;
    DSOrdersOrderNum.AfterLoadDFMValues;
    DSOrdersTrip.AfterLoadDFMValues;
    DSOrdersInvoiceTotal.AfterLoadDFMValues;
    DSOrdersInvoiceNum.AfterLoadDFMValues;
    DSOrdersDate.AfterLoadDFMValues;
    DSOrdersShipCust.AfterLoadDFMValues;
    DSOrdersShipName.AfterLoadDFMValues;
    DSOrdersShipCity.AfterLoadDFMValues;
    DSOrdersShipState.AfterLoadDFMValues;
    DSOrdersPcsTotal.AfterLoadDFMValues;
    DSOrdersWindowArriveTime.AfterLoadDFMValues;
    DSLines.AfterLoadDFMValues;
    DSLinesAutoInc.AfterLoadDFMValues;
    DSLinesOrderNum.AfterLoadDFMValues;
    DSLinesProd.AfterLoadDFMValues;
    DSLinesQtyShip.AfterLoadDFMValues;
    DSLinesCatchWeight.AfterLoadDFMValues;
    DSLinesProdUnit.AfterLoadDFMValues;
    DSLinesProdSize.AfterLoadDFMValues;
    DSLinesProdDescription.AfterLoadDFMValues;
    DSLinesProdBrand.AfterLoadDFMValues;
    WDSLines.AfterLoadDFMValues;
    WDSOrders.AfterLoadDFMValues;
  end;
end;

end.