unit Login;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.StdCtrls, WEBLib.ExtCtrls;

type
  TFLogin = class(TForm)
    DSUsers: TXDataWebDataSet;
    DSUsersAutoInc: TIntegerField;
    DSUsersUsername: TStringField;
    DSUsersPassword: TStringField;
    LoginPnl: TPanel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    VersionTxt: TLabel;
    UsernameEdit: TEdit;
    PasswordEdit: TEdit;
    LoginBtn: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure LoginBtnClick(Sender: TObject);
    procedure DSUsersAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  protected
    procedure LoadDFMValues; override;
  public
    { Public declarations }
  end;

var
  FLogin: TFLogin;

implementation

Uses Data, Main;


procedure TFLogin.DSUsersAfterOpen(DataSet: TDataSet);
begin
  if (DSUsers.RecordCount > 0) and
    (DSUsersPassword.AsString = PasswordEdit.Text) then
  begin
    Application.CreateForm(TFMain, FMain);
  end
  else
  begin
    DSUsers.Close;
    ShowMessage('Invalid Login Info');
  end;
end;

procedure TFLogin.LoginBtnClick(Sender: TObject);
begin
  if UsernameEdit.Text = '' then
  begin
    ShowMessage('Please Enter a Username');
    Exit;
  end;
  if PasswordEdit.Text = '' then
  begin
    ShowMessage('Please Enter a Password');
    Exit;
  end;
  DSUsers.QueryString := '$filter=(Username eq ''' +
    UsernameEdit.Text + ''')';
  DSUsers.Load;
end;

procedure TFLogin.WebFormShow(Sender: TObject);
begin
  LoginPnl.Left := Round((FLogin.Width / 2) - (LoginPnl.Width / 2));
  LoginPnl.Top := Round((FLogin.Height / 2) - (LoginPnl.Height / 2));
  if LoginPnl.Top < 0 then LoginPnl.Top := 2;
end;

procedure TFLogin.LoadDFMValues;
begin
  inherited LoadDFMValues;

  LoginPnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  VersionTxt := TLabel.Create(Self);
  UsernameEdit := TEdit.Create(Self);
  PasswordEdit := TEdit.Create(Self);
  LoginBtn := TButton.Create(Self);
  DSUsers := TXDataWebDataSet.Create(Self);
  DSUsersAutoInc := TIntegerField.Create(Self);
  DSUsersUsername := TStringField.Create(Self);
  DSUsersPassword := TStringField.Create(Self);

  LoginPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  VersionTxt.BeforeLoadDFMValues;
  UsernameEdit.BeforeLoadDFMValues;
  PasswordEdit.BeforeLoadDFMValues;
  LoginBtn.BeforeLoadDFMValues;
  DSUsers.BeforeLoadDFMValues;
  DSUsersAutoInc.BeforeLoadDFMValues;
  DSUsersUsername.BeforeLoadDFMValues;
  DSUsersPassword.BeforeLoadDFMValues;
  try
    Name := 'FLogin';
    Width := 557;
    Height := 863;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    LoginPnl.SetParentComponent(Self);
    LoginPnl.Name := 'LoginPnl';
    LoginPnl.AlignWithMargins := True;
    LoginPnl.Left := 8;
    LoginPnl.Top := 8;
    LoginPnl.Width := 541;
    LoginPnl.Height := 841;
    LoginPnl.ElementClassName := 'card';
    LoginPnl.HeightPercent := 10;
    LoginPnl.Anchors := [];
    LoginPnl.BorderColor := clSilver;
    LoginPnl.BorderStyle := bsSingle;
    LoginPnl.ChildOrder := 5;
    LoginPnl.ElementBodyClassName := 'card-body';
    LoginPnl.Font.Charset := DEFAULT_CHARSET;
    LoginPnl.Font.Color := clWindowText;
    LoginPnl.Font.Height := -21;
    LoginPnl.Font.Name := 'Arial';
    LoginPnl.Font.Style := [];
    LoginPnl.ParentFont := False;
    WebLabel1.SetParentComponent(LoginPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 215;
    WebLabel1.Top := 112;
    WebLabel1.Width := 103;
    WebLabel1.Height := 23;
    WebLabel1.Anchors := [];
    WebLabel1.AutoSize := False;
    WebLabel1.Caption := 'Username';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -21;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightStyle := ssPercent;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthStyle := ssPercent;
    WebLabel2.SetParentComponent(LoginPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 215;
    WebLabel2.Top := 197;
    WebLabel2.Width := 103;
    WebLabel2.Height := 22;
    WebLabel2.Anchors := [];
    WebLabel2.AutoSize := False;
    WebLabel2.Caption := 'Password';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -21;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebLabel3.SetParentComponent(LoginPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 139;
    WebLabel3.Top := 48;
    WebLabel3.Width := 264;
    WebLabel3.Height := 22;
    WebLabel3.Anchors := [];
    WebLabel3.Caption := 'Maplevale Cash && Carry Pickup';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -19;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.ParentFont := False;
    VersionTxt.SetParentComponent(LoginPnl);
    VersionTxt.Name := 'VersionTxt';
    VersionTxt.Left := 510;
    VersionTxt.Top := 813;
    VersionTxt.Width := 18;
    VersionTxt.Height := 16;
    VersionTxt.Alignment := taRightJustify;
    VersionTxt.Anchors := [];
    VersionTxt.Caption := '1.0';
    VersionTxt.Font.Charset := DEFAULT_CHARSET;
    VersionTxt.Font.Color := clWindowText;
    VersionTxt.Font.Height := -13;
    VersionTxt.Font.Name := 'Arial';
    VersionTxt.Font.Style := [];
    VersionTxt.ParentFont := False;
    UsernameEdit.SetParentComponent(LoginPnl);
    UsernameEdit.Name := 'UsernameEdit';
    UsernameEdit.Left := 181;
    UsernameEdit.Top := 144;
    UsernameEdit.Width := 171;
    UsernameEdit.Height := 40;
    UsernameEdit.Anchors := [];
    UsernameEdit.ChildOrder := 1;
    UsernameEdit.ElementClassName := 'form-control';
    PasswordEdit.SetParentComponent(LoginPnl);
    PasswordEdit.Name := 'PasswordEdit';
    PasswordEdit.Left := 181;
    PasswordEdit.Top := 228;
    PasswordEdit.Width := 171;
    PasswordEdit.Height := 39;
    PasswordEdit.Anchors := [];
    PasswordEdit.ChildOrder := 1;
    PasswordEdit.ElementClassName := 'form-control';
    PasswordEdit.PasswordChar := '*';
    LoginBtn.SetParentComponent(LoginPnl);
    LoginBtn.Name := 'LoginBtn';
    LoginBtn.Left := 192;
    LoginBtn.Top := 292;
    LoginBtn.Width := 141;
    LoginBtn.Height := 53;
    LoginBtn.Anchors := [];
    LoginBtn.Caption := 'Login';
    LoginBtn.ChildOrder := 4;
    LoginBtn.ElementClassName := 'btn btn-primary';
    SetEvent(LoginBtn, Self, 'OnClick', 'LoginBtnClick');
    DSUsers.SetParentComponent(Self);
    DSUsers.Name := 'DSUsers';
    DSUsers.AfterOpen := DSUsersAfterOpen;
    DSUsers.EntitySetName := 'CNCUsers';
    DSUsers.Connection := Dm.ApiConnection;
    DSUsers.Left := 16;
    DSUsers.Top := 792;
    DSUsersAutoInc.SetParentComponent(DSUsers);
    DSUsersAutoInc.Name := 'DSUsersAutoInc';
    DSUsersAutoInc.FieldName := 'AutoInc';
    DSUsersAutoInc.Required := True;
    DSUsersUsername.SetParentComponent(DSUsers);
    DSUsersUsername.Name := 'DSUsersUsername';
    DSUsersUsername.FieldName := 'Username';
    DSUsersUsername.Size := 50;
    DSUsersPassword.SetParentComponent(DSUsers);
    DSUsersPassword.Name := 'DSUsersPassword';
    DSUsersPassword.FieldName := 'Password';
    DSUsersPassword.Size := 50;
  finally
    LoginPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    VersionTxt.AfterLoadDFMValues;
    UsernameEdit.AfterLoadDFMValues;
    PasswordEdit.AfterLoadDFMValues;
    LoginBtn.AfterLoadDFMValues;
    DSUsers.AfterLoadDFMValues;
    DSUsersAutoInc.AfterLoadDFMValues;
    DSUsersUsername.AfterLoadDFMValues;
    DSUsersPassword.AfterLoadDFMValues;
  end;
end;

end.